import { useQueryClient } from '@tanstack/react-query';
import { FC, PropsWithChildren, useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '../../api';
import { RoutePath } from '../../config';
import { AuthContext, ApiResponse } from '../../model';

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [isClient, setIsClient] = useState<boolean>(true);
  const [userId, setUserId] = useState<number| null>(null);
  const isClientRef = useRef<boolean>(true); 

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response: ApiResponse<any> = await AuthApi.checkAuth();
        if (response.status === 'success') {
          setAuthorized(true);
          setIsClient(response.data.role === 'client');
          isClientRef.current = response.data.role === 'client'; 
          setUserId(response.data.id);
          setIsLoading(false);
          
          const lastPath = sessionStorage.getItem('lastPath');
          if (lastPath) {
            navigate(lastPath);
            sessionStorage.removeItem('lastPath');
          }
        } else {
          setAuthorized(false);
          setIsLoading(false);
        }
      } catch (error) {
        setAuthorized(false);
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  const signIn = useCallback((userId: number, isClient: boolean) => {
    setAuthorized(true);
    setIsClient(isClient);
    setUserId(userId);
    isClientRef.current = isClient; 
  }, []);

  const signOut = useCallback(async () => {
    await AuthApi.logOut();
    setAuthorized(false);
    setUserId(null);
    navigate(isClientRef.current ? RoutePath.ClientLogin : RoutePath.DoctorLogin); 
    queryClient.clear();
  }, [navigate, queryClient]);

  return (
    <AuthContext.Provider value={{ isAuthorized: authorized, userId, isClient, isLoading,  signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
