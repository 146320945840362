import { Transform } from 'class-transformer';
import parse from 'date-fns/parse';
import type { PatientContact } from './PatientContact';
import type { PatientDocument } from './PatientDocument';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const PHONE_CONTACT_TYPE_ID = 18;
const EMAIL_CONTACT_TYPE_ID = 19;

const phoneUtil = PhoneNumberUtil.getInstance();

export class Patient {
  readonly active_order: {
    id: number;
  } | null = null;

  @Transform(({ value }) => parse(value, 'dd-MM-yyyy', new Date()), { toClassOnly: true })
  readonly birthdate!: Date;

  readonly contacts: PatientContact[] = [];
  readonly documents: PatientDocument[] = [];
  readonly midname!: string;
  readonly name!: string;
  readonly surname!: string;
  readonly id!: number;

  get email(): string | undefined {
    const email = this.contacts.find((contact) => contact.contact_type.id === EMAIL_CONTACT_TYPE_ID);
    return email?.value;
  }

  get phone(): string | undefined {
    const rawPhoneNumber = this.contacts.find((contact) => contact.contact_type.id === PHONE_CONTACT_TYPE_ID);

    if (!rawPhoneNumber) {
      return undefined;
    }

    const phoneNumber = phoneUtil.parseAndKeepRawInput(rawPhoneNumber.value, 'RU');
    return phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
  }

  get initials(): string {
    return `${this.surname.charAt(0)}${this.name.charAt(0)}`;
  }

  get fullName(): string {
    let fullName = this.surname;

    if (this.name) {
      fullName += ` ${this.name.charAt(0)}.`;
    }

    if (this.midname) {
      fullName += ` ${this.midname.charAt(0)}.`;
    }

    return fullName;
  }

  includes(searchText: string): boolean {
    return (
      this.surname.toLowerCase().includes(searchText) ||
      this.name.toLowerCase().includes(searchText) ||
      this.midname.toLowerCase().includes(searchText)
    );
  }
}
