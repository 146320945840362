import { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTimer } from 'react-timer-hook';
import { AuthApi } from '../../api';
import { ClientLoginFormFields, useAuthContext, useClientLoginForm } from '../../hooks';
import { normalizePhoneNumber } from '../../utils';
import { CodeField } from '../atoms';

function getExpiryTimestamp(): Date {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 59);
  return expiryTimestamp;
}

export const ClientLoginFormSecondStep: FC = () => {
  const { signIn } = useAuthContext();
  const { minutes, seconds, restart } = useTimer({ expiryTimestamp: getExpiryTimestamp() });
  const { handleSubmit } = useClientLoginForm();

  const onSubmit: SubmitHandler<ClientLoginFormFields> = useCallback(
    async ({ code, phoneNumber }) => {
      const response = await AuthApi.validateCode(normalizePhoneNumber(phoneNumber), code);
      const userId = response.data.userId;
      signIn(userId, true);
    },
    [signIn],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mx-auto w-full max-w-[360px]">
      <h1 className="mb-[36px] text-center text-[30px] leading-[36px] font-medium">Ввести смс-код</h1>
      <CodeField />
      <div className="mb-[24px] text-center">
        {seconds > 0 ? (
          <>
            <span className="text-brand-1/20">отправить повторно через</span>&nbsp;{String(minutes).padStart(2, '0')}:
            {String(seconds).padStart(2, '0')}
          </>
        ) : (
          <button className="underline cursor-pointer" onClick={() => restart(getExpiryTimestamp())} type="button">
            Отправить код
          </button>
        )}
      </div>
      <button className="button button--block button--primary" type="submit">
        Войти
      </button>
    </form>
  );
};
