import { useQuery } from '@tanstack/react-query';
import plural from 'plural-ru';
import type { FC } from 'react';
import { OrderApi, ReceiptApi } from '../api';
import { PrivateRoute } from '../components';
import { ClientInnerPageLayout } from '../layouts';
import { Receipt } from '../model';
import { formatDurationRu, formatMedicinePlural } from '../utils';

export const ClientMedicinePage: FC = () => {
  const { data: receipts } = useQuery({
    queryKey: [ReceiptApi.KEY, 'get'],
    queryFn: () => ReceiptApi.get(),
  });

  return (
    <PrivateRoute>
      <ClientInnerPageLayout>
        {receipts?.map((receipt) => (
          <ReceiptView key={receipt.order_id} receipt={receipt} />
        ))}
      </ClientInnerPageLayout>
    </PrivateRoute>
  );
};

const ReceiptView: FC<{ receipt: Receipt }> = ({ receipt }) => {
  const { data: order } = useQuery({
    queryKey: [OrderApi.KEY, 'getById', receipt.order_id],
    queryFn: () => OrderApi.getById(receipt.order_id, true),
  });

  return (
    <div className="mb-2 bg-brand-1 rounded-lg text-white">
      <header className="flex justify-between px-[24px] py-[16px]">
        <div className="flex gap-[12px]">
          <span>Заказ #{receipt.order_id}</span>
          {order && (
            <>
              <span className="text-white/20">|</span>
              <span>
                Статус <span className="text-brand-5">{order.status.value}</span>
              </span>
            </>
          )}
        </div>
        <span>
          Врач <span className="text-brand-5">{receipt.doctor.fullName}</span>
        </span>
      </header>
      <div className="bg-white py-[12px] rounded-lg text-brand-1">
        <table className="table">
          <thead>
            <tr>
              <th className="w-[24px] border-none">&nbsp;</th>
              <th className="text-left">Лекарство</th>
              <th className="text-left">Количество</th>
              <th className="text-left">Период</th>
              <th className="text-left">Время</th>
              <th className="w-[24px] border-none">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {receipt.receipt_medicine.map(({ amount, daytime, duration, eating, frequency, medicine }, index) => (
              <tr className="hoverable" key={index}>
                <td className="border-none" />
                <td className="font-bold">{medicine.name}</td>
                <td>
                  {eating.value}&nbsp;{formatMedicinePlural(Number(eating.value))}&nbsp;{frequency.value}
                </td>
                <td>
                  на протяжении&nbsp;
                  {formatDurationRu({
                    days: duration,
                  })}
                </td>
                <td>{daytime? daytime.value: ""}</td>
                <td className="border-none" />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
