export enum RoutePath {
  Landing = '/order/now',

  ClientCalendar = '/client/calendar',
  ClientLogin = '/client/login',
  ClientRegister = '/client/register',
  ClientCreateOrder = '/client/create-order',
  ClientMedicine = '/client/medicine',
  
  ClientOrderHistory = '/client/order-history',
  ClientSettings = '/client/settings',
  DoctorCreateOrder = '/doctor/create-order',
  DoctorCreatePatient = '/doctor/patients/create',
  DoctorCreateAddress = '/doctor/create/address/:patientId',
  DoctorLogin = '/doctor/login',
  DoctorMedicine = '/doctor/medicine',
  DoctorOrderHistory = '/doctor/order-history',
  DoctorOrderHistoryItem = '/doctor/order-history/:orderId',
  DoctorPatientDetails = '/doctor/patients/:patientId',
  DoctorEditPatient = '/doctor/patients/:patientId/edit', 
  DoctorPatientOrderDetails = '/doctor/patients/:patientId/orders/:orderId',
  DoctorPatients = '/doctor/patients',
}
