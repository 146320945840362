import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from '../../api';
import { normalizePhoneNumber } from '../../utils';
import styles from './Step.module.css';

interface RegistrationFormFields {
  surname: string;
  name: string;
  midname: string;
  username: string;
  password: string;
  confirmPassword: string;
  consent: boolean;
}

interface Step0Props {
  onNext: (data: { userId: number }) => void;
}


export const Step0: FC<Step0Props> = ({ onNext }) => {
  const { 
    register, 
    handleSubmit, 
    control, 
    formState: { errors } 
  } = useForm<RegistrationFormFields>();

  const { mutate } = useMutation({
    mutationFn: AuthApi.leadClientRegister,
    onSuccess: (response) => {
      const userId = response.data.userId;
      onNext({ userId }); 
    },
    onError: (err) => {
      console.error("Ошибка регистрации:", err);
    },
  });

  const onSubmit: SubmitHandler<RegistrationFormFields> = async (formData) => {
    const { consent, ...requestData } = formData;
    const normalizedPhone = normalizePhoneNumber(requestData.username);
    mutate({ ...requestData, username: normalizedPhone });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.gridStep0Container}>

      <div className={classNames(styles.column, "text-brand-1  rounded-l-lg")}>
        <header className={classNames(styles.header, "bg-brand-6 rounded-tl-lg")}>
          <h2 className={classNames(styles.header,"leading-normal font-bold")}>Личные данные</h2>
        </header>
        <div className="px-[20px] py-[20px] space-y-2">
          <input 
            {...register('surname', { required: true })}
            className={classNames('input w-full', errors.surname && 'input--invalid')}
            placeholder="Фамилия"
            type="text"
          />
          {errors.surname && <p className="text-red-500 text-xs mt-1">Обязательное поле</p>}

          <input
            {...register('name', { required: true })}
            className={classNames('input w-full', errors.name && 'input--invalid')}
            placeholder="Имя"
            type="text"
          />
          {errors.name && <p className="text-red-500 text-xs mt-1">Обязательное поле</p>}

          <input
            {...register('midname', { required: true })}
            className={classNames('input w-full', errors.midname && 'input--invalid')}
            placeholder="Отчество"
            type="text"
          />
          {errors.midname && <p className="text-red-500 text-xs mt-1">Обязательное поле</p>}
          <Controller
            control={control}
            name="username"
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <InputMask
                {...field}
                alwaysShowMask
                className={classNames('input w-full', fieldState.invalid && 'input--invalid')}
                mask="+7 999 999 99 99"
                maskPlaceholder={null}
                placeholder="Номер телефона"
                type="tel"
                value={field.value ?? ''}
              />
            )}
          />
          {errors.username && <p className="text-red-500 text-xs mt-1">Обязательное поле</p>}
        </div>
      </div>

      <div className={classNames(styles.column, "rounded-r-lg text-brand-1")}>
        <header className={classNames(styles.header, "bg-brand-6 rounded-tl-lg")}>
          <h2 className={classNames(styles.header,"leading-normal font-bold")}>Пароль</h2>
        </header>
        <div className="grow p-[24px] space-y-4">
          <div>
            <input
              {...register('password', { required: true })}
              className={classNames('input w-full', errors.password && 'input--invalid')}
              placeholder="Пароль"
              type="password"
            />
            {errors.password && <p className="text-red-500 text-xs mt-1">Обязательное поле</p>}
          </div>

          <div>
            <input
              {...register('confirmPassword', {
                required: true,
                validate: (value, formValues) =>
                  value === formValues.password || 'Пароли не совпадают',
              })}
              className={classNames('input w-full', errors.confirmPassword && 'input--invalid')}
              placeholder="Повторите пароль"
              type="password"
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-xs mt-1">{errors.confirmPassword.message}</p>
            )}
          </div>

          <div>
            <label className="flex items-center gap-2">
              <input
                {...register('consent', { required: 'Необходимо согласие' })}
                type="checkbox"
                className={styles.checkbox}
              />
              <span>
                Я согласен с{' '}
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-brand-5 underline"
                >
                  обработкой персональных данных
                </a>
              </span>
            </label>
            {errors.consent && (
              <p className="text-red-500 text-xs mt-1">{errors.consent.message}</p>
            )}
          </div>
        </div>
        <footer className="flex justify-end p-[24px] border-t border-dashed border-brand-1/50">
          <button
            className="button button--primary w-full flex items-center justify-between px-2 text-left"
            type="submit"
          >
            Далее
            <span className="ml-2">→</span>
          </button>
        </footer>
      </div>
    </form>
  );
};

