import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AuthApi, CommonApi } from '../api';
import { AuthRoute, Select } from '../components';
import { RoutePath } from '../config';
import { useAuthContext } from '../hooks';
import { DoctorAuthLayout } from '../layouts';

interface FormFields {
  hospital_id: number;
  password: string;
  username: string;
}

export const DoctorLoginPage: FC = () => {
  const { signIn } = useAuthContext();
  const { data: hospitals = [] } = useQuery({
    queryKey: [CommonApi.KEY, 'getHospitals'],
    queryFn: CommonApi.getHospitals,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormFields>({
    defaultValues: {
      username: 'doctor_demo',
    },
  });

  const onSubmit: SubmitHandler<FormFields> = useCallback(
    async (data) => {
      const response = await AuthApi.authorizeDoctor({
        ...data,
        hospital_id: data.hospital_id,
      });
      const userId = response.data.userId;
      signIn(userId, false);
    },
    [signIn],
  );

  return (
    <AuthRoute redirectPath={RoutePath.DoctorCreateOrder}>
      <DoctorAuthLayout>
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto w-full max-w-[360px] grid gap-[12px]">
          <h1 className="mb-[36px] text-center text-[30px] leading-[36px] font-medium">Личный кабинет</h1>
          <input
            {...register('username', {
              required: true,
            })}
            className={classNames('input', errors.username && 'input--invalid')}
            placeholder="Имя пользователя"
            type="text"
          />
          <input
            {...register('password', {
              required: true,
            })}
            className={classNames('input', errors.password && 'input--invalid')}
            placeholder="Пароль"
            type="password"
          />
          <Controller
            control={control}
            name="hospital_id"
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => {
              return (
                <Select
                  error={fieldState.error}
                  onChange={field.onChange}
                  options={hospitals.map((hospital) => ({
                    label: hospital.name,
                    value: hospital.id,
                  }))}
                  placeholder="Выбор клиники"
                  value={field.value}
                />
              );
            }}
          />

          <button className="button button--block button--primary" type="submit">
            Войти
          </button>
        </form>
      </DoctorAuthLayout>
    </AuthRoute>
  );
};
