import { FC, useCallback } from 'react';
import { ReactComponent as SuccessIcon } from '../../assets/images/success.svg';
import { Modal } from '../molecules';
import { OrderApi } from '../../api';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom'; 
import { RoutePath } from '../../config';
import { generatePath } from 'react-router-dom';

interface Props {
  onClose(): void;
  receiptId?: number;
  address?: number;
}

export const ConfirmOrderModal: FC<Props> = ({ onClose, receiptId, address }) => {
  const navigate = useNavigate(); 

  const { mutate } = useMutation({
    mutationFn: ({ receiptId, address }: { receiptId: number, address: number }) => OrderApi.confirm(receiptId, address),
    onSuccess(data) {
      const patientId = data.data.patient_id;
      navigate(generatePath(RoutePath.DoctorPatientDetails, {
        patientId: patientId,
      }));
      onClose();
    },
  });

  const handleConfirmClick = useCallback(() => {
    if (receiptId !== undefined && address !== undefined) {
      mutate({ receiptId, address });
    }
  }, [receiptId, address, mutate]);

  return (
    <Modal isOpen={Boolean(receiptId)} onClose={onClose}>
      <div className="p-[72px] text-center">
        <span className="flex justify-center">
          <SuccessIcon />
        </span>
        <h2 className="mt-[24px] text-[24px] leading-none">Заказ успешно собран!</h2>
        <span className="mt-[36px] block">Чтобы отправить клиенту, нажмите “подтвердить” </span>
        <footer className="mt-[36px] flex justify-center gap-[24px]">
          <button className="button button--outlined px-[40px]" onClick={onClose} type="button">
            Отменить
          </button>
          <button className="button button--primary px-[40px]" onClick={handleConfirmClick} type="button">
            Подтвердить
          </button>
        </footer>
      </div>
    </Modal>
  );
};
