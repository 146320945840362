import { FC, useState, Fragment, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { Combobox, Transition } from "@headlessui/react";
import { PeopleApi } from "../../api";
import { AddressResponse, Address, DaDataAddress } from "../../model/Address";
import { FieldError } from "react-hook-form";

interface Props {
  className?: string;
  error?: FieldError;
  value: number | null;
  onChange: (id: number) => void;
  patientId: number | null;
}

export const AddressAutofill: FC<Props> = ({ className, error, onChange, value, patientId }) => {
  const [query, setQuery] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 300);
    return () => clearTimeout(handler);
  }, [query]);


  const {
    data: addressesData = { total: 0, items: [] },
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["normalizeAddress", debouncedQuery, patientId],
    queryFn: () => PeopleApi.normalizeAddress(debouncedQuery),
    enabled: !!debouncedQuery,
  });

  const { mutateAsync: createAddress, isLoading: isCreating } = useMutation({
    mutationFn: (data: AddressResponse) => PeopleApi.createPatientsAddress(data),
    onSuccess: (data) => {
      console.log("Адрес успешно сохранен", data);
    },
    onError: (error) => {
      console.error("Ошибка при сохранении адреса:", error);
    },
  });

  const addresses = addressesData.items;

  const handleSelect = async (address: DaDataAddress) => {
    try {
      if (patientId === null || patientId === undefined) {
        throw new Error("patientId не может быть null или undefined");
      }

      const addressToSend: AddressResponse = {
        ...address,
        people_main_id: patientId,
      };

      const response = await createAddress(addressToSend);
      setSelectedAddress(response.data);
      if (!response.data?.id) {
        throw new Error("ID адреса отсутствует в ответе сервера");
      }
      onChange(response.data.id);
    } catch (error) {
      console.error("Ошибка при сохранении адреса:", error);
    }
  };

  return (
    <Combobox 
        value={value}
        onChange={onChange}
      >
      <div className={classNames("relative", className)}>
        <Combobox.Input
          className={classNames("input input--block bg-white text-left", {
            "input--invalid": Boolean(error),
          })}
          displayValue={() => {
            return selectedAddress?.address_text || "";
          }}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Введите адрес"
        />
        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-[300px] w-full overflow-auto rounded-md bg-white shadow-lg border border-brand-2">
            {isLoading && (
              <Combobox.Option disabled className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value="">
                Загрузка...
              </Combobox.Option>
            )}
            {isSuccess && addresses.length === 0 && (
              <Combobox.Option disabled className="relative cursor-pointer select-none p-2 hover:bg-brand-6" value="">
                Ничего не найдено
              </Combobox.Option>
            )}
            {addresses.map((address) => (
              <Combobox.Option
                key={address.id}
                className="relative cursor-pointer select-none p-2 hover:bg-brand-6"
                value={address.id}
                onClick={() => handleSelect(address)} 
              >
                {({ selected }) => (
                  <>
                    <span
                      className={classNames('block truncate', {
                        'font-bold': selected,
                      })}
                    >
                      {address.source}
                    </span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
