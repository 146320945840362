import { useQuery, useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import format from 'date-fns/format';
import { FC, useState, useRef } from 'react';
import { Link, generatePath, useParams, useNavigate } from 'react-router-dom';
import { OrderApi, PeopleApi, CommonApi } from '../api';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { DoctorOrderDetails, PrivateRoute } from '../components';
import { RoutePath } from '../config';
import { queryClient } from '../App';
import { DoctorInnerPageLayout } from '../layouts';
import { formatMoney } from '../utils';
import PdfIcon from '../assets/icons/pdf.png'
import {ReactComponent as TrashIcon } from '../assets/icons/trash.svg'
import styles from './DoctorPatientDetailsPage.module.css';
import axios from 'axios';

export const DoctorPatientDetailsPage: FC = () => {
  const [tab, setTab] = useState<'history' | 'active' | 'documents'>('history');
  const { patientId } = useParams<{ patientId?: string }>(); 
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();
  const { data: patient } = useQuery({
    enabled: !!patientId,
    queryFn: () => patientId ? PeopleApi.getPatientDetails(patientId) : Promise.reject(),
    queryKey: [PeopleApi.KEY, 'getPatientDetails', patientId],
  });

  const { data: orderHistory = [] } = useQuery({
    enabled: !!patientId,
    queryFn: () => patientId ? OrderApi.getHistoryByPatientId(patientId) : Promise.reject(),
    queryKey: [OrderApi.KEY, 'getHistoryByPatientId', patientId],
  });

  const { mutate: deleteDocument } = useMutation({
    mutationFn: (documentId: number) => CommonApi.deleteDocument(documentId),
    onSuccess: () => {
      queryClient.invalidateQueries([PeopleApi.KEY, 'getPatientDetails', patientId]);
    },
  });

  const { mutate: uploadDocument } = useMutation({
    mutationFn: (formData: FormData) => CommonApi.uploadDocument(formData),
    onSuccess: () => {
      queryClient.invalidateQueries([PeopleApi.KEY, 'getPatientDetails', patientId]);
    },
  });

  if (!patientId || !patient) {
    return (
      <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
        <DoctorInnerPageLayout />
      </PrivateRoute>
    );
  }

  const handleEditClick = () => {
    navigate(generatePath(RoutePath.DoctorEditPatient, { patientId }));
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setIsUploading(true);
    setUploadError(null);

    const formData = new FormData();
    formData.append("document", file);
    formData.append("patient_id", patientId!);

    try {
      uploadDocument(formData);

    } catch (error) {
      setUploadError("Ошибка загрузки");
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };
    const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const file = e.dataTransfer.files?.[0];
      if (file) {
        const event = {
          target: {
            files: [file], 
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>;
        handleFileSelect(event);
      }
    };

  return (
    <PrivateRoute redirectPath={RoutePath.DoctorLogin}>
      <DoctorInnerPageLayout>
        <div className="bg-brand-6 rounded-lg">
          <header className="p-[24px]">
            <Link className="flex items-center gap-[6px] text-[20px] font-bold" to={RoutePath.DoctorPatients}>
              <BackIcon />
              Вернуться назад
            </Link>
          </header>
          <div className="bg-white rounded-lg">
            <div className="flex gap-[24px] px-[24px] py-[36px]">
              <div className="flex items-center justify-center w-[120px] h-[120px] bg-brand-7 rounded-md text-[36px]">
                {patient.initials}
              </div>
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="mb-[6px] text-[24px]">{patient.fullName}</h1>
                  <span className="block">{format(new Date(patient.birthdate), 'dd.MM.yyyy')}</span>
                </div>
                <div className="leading-[24px]">
                  {patient.phone && (
                    <div className="flex gap-[12px]">
                      <span className="flex-1 text-brand-1/50">Телефон</span>
                      <span>{patient.phone}</span>
                    </div>
                  )}
                  {patient.email && (
                    <div className="flex gap-[12px]">
                      <span className="flex-1 text-brand-1/50">Электронная почта</span>
                      <span>{patient.email}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end px-[24px] pb-[24px]">
              <button className="button button--primary px-[40px]" onClick={handleEditClick}>
                Редактировать
              </button>
            </div>
            <div className="bg-brand-6 rounded-lg">
              <header className="flex gap-[36px] px-[24px]">
                <button className={classNames(styles.tab, tab === 'history' && styles.active)} onClick={() => setTab('history')} type="button">
                  История заказов
                </button>
                {patient.active_order && (
                  <button className={classNames(styles.tab, tab === 'active' && styles.active)} onClick={() => setTab('active')} type="button">
                    Действующий заказ
                  </button>
                )}
                <button
                  className={classNames(styles.tab, tab === 'documents' && styles.active)}
                  onClick={() => setTab('documents')}
                  type="button"
                >
                  Документы
              </button>
              </header>
              {tab === 'history' && (
                <div className="px-[24px] pt-[24px] pb-[12px] bg-white rounded-lg">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Номер заказа</th>
                        <th>Доставлен</th>
                        <th>Лекарства</th>
                        <th>Цена</th>
                        <th>Статус</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderHistory.map((order) => (
                        <tr key={order.id}>
                          <td>№{order.id}</td>
                          <td>{format(new Date(order.status_date), 'dd.MM.yyyy')}</td>
                          <td>{order.medicine_count}</td>
                          <td>{formatMoney(order.price)}</td>
                          <td>{order.status.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {tab === 'active' && patient.active_order && <DoctorOrderDetails orderId={patient.active_order.id} />}
              </div>
              {tab === 'documents' && (
              <div className="px-[24px] pt-[24px] pb-[12px] bg-white rounded-lg">
                <table className="table w-full">
                <tbody>
                {patient.documents && patient.documents.length > 0 ? (
                  patient.documents.map((doc, index) => {
                    const fileName = doc.title;
                    const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
                    const fileExtension = fileName.split('.').pop();

                    return (
                      <tr key={doc.id}>
                        <td className="flex items-center gap-4">
                          <a
                            href={`https://pax.sptnk.co${doc.document}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer"
                          >
                            <img src={PdfIcon} alt="PDF icon" width={30} height={30} />
                          </a>
                          <div>
                            <span className="block font-medium">{fileNameWithoutExtension}</span>
                            <span className="block text-brand-1/30 text-[14px] leading-[20px]">{fileName}</span>
                          </div>
                        </td>
                        <td>
                        <button
                          onClick={() => deleteDocument(doc.id)}
                          className="inline-flex items-center justify-center w-[32px] h-[32px] bg-brand-3 rounded-md"
                        >
                          <TrashIcon/>
                        </button>
                          </td>
                        </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2}>Нет доступных документов</td>
                  </tr>
                )}
                </tbody>
                </table>
                <div className="mt-4">
                <div
                  className="border-dashed border-2 border-gray-400 rounded-lg p-4 text-center cursor-pointer hover:border-brand-3 transition-colors"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleFileDrop}
                >
                  <p>
                    <strong className="block text-[16px] font-bold">Перетащите файлы, чтобы начать загрузку</strong>
                    <span className="block text-brand-1/30 text-[14px] leading-[20px]">Максимальный размер файла - 5 Мб</span>
                  </p>
                  <span
                    className="text-brand-5 underline cursor-pointer mt-2 block text-center"
                    onClick={handleUploadClick}
                  >
                    Загрузить
                  </span>
                </div>

                <input
                  type="file"
                  accept="application/pdf"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                />
              </div>
              </div>
            )}
            </div>
          </div>
      </DoctorInnerPageLayout>
    </PrivateRoute>
  );
};