import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import { ApiResponse, CollectionApiResponse, Patient, PatientContact, User } from '../model';
import { AddressResponse, Address } from '../model/Address';
import { PatientDocument } from '../model/PatientDocument';

export interface UserDTO {
  address_text: string;
  midname: string;
  name: string;
  surname: string;
}

export interface PatientDTO {
  birthdate: string;
  code: string;
  gender_id: number;
  midname: string;
  name: string;
  surname: string;
  email: string;
  contacts: PatientContact[];
  documents: PatientDocument[];
  username: string; // phone
}

export class PeopleApi {
  static readonly KEY = 'people';

  static async me(isClient: boolean): Promise<User> {
    const { data } = await axios.get<ApiResponse<User>>(isClient ? '/api/v1/client/people' : '/api/v1/people/me');
    return plainToInstance(User, data.data);
  }

  static async getPatients(search?: string, page?: number, size?: number): Promise<{total: number, items: Patient[]}> {
    const { data } = await axios.get<CollectionApiResponse<Patient>>('/api/v1/people', {
      params: {
        search,
        page,
        size,
      },
    });
    return {
      total: data.total, 
      items: plainToInstance(Patient, data.items), 
    };
  }

  static async getPatientDetails(id: string): Promise<Patient> {
    const { data } = await axios.get<ApiResponse<Patient>>(`/api/v1/people/${id}`);

    return plainToInstance(Patient, data.data);
  }

  static async updateMe(body: UserDTO): Promise<User> {
    const { data } = await axios.put<ApiResponse<User>>('/api/v1/client/people', body);

    return plainToInstance(User, data.data);
  }

  static async createPatient(body: PatientDTO): Promise<any> {
    const { data } = await axios.post<ApiResponse<any>>('/api/v1/doctor/patient', body);

    return data;
  }

  static async sendCodeToPatient(phone: string): Promise<ApiResponse> {
    const { data } = await axios.post<ApiResponse>('/api/v1/doctor/send_code', { phone });

    return data;
  }
  static async updatePatient(patientId: string, updatedData: any): Promise<ApiResponse> {
    const { data } = await axios.put<ApiResponse>(`/api/v1/people/${patientId}`, { updatedData});

    return data;
  }
  static async createPatientsAddress(addressData: AddressResponse): Promise<ApiResponse<Address>> {
    const { data } = await axios.post<ApiResponse<Address>>(`/api/v1/doctor/create/address`, addressData);
    return data;
  }

  static async getAddress(search?: string, patientId?: number | null): Promise<{total: number, items: Address[]}> {
    const { data } = await axios.get<CollectionApiResponse<Address>>('/api/v1/address/list', {
      params: {
        search,
        patientId,
      },
    });
    return {
      total: data.total, 
      items: data.items, 
    };
  }
  
  static async normalizeAddress(search?: string): Promise<{total: number, items:AddressResponse[]}> {
    const { data } = await axios.get<CollectionApiResponse<AddressResponse>>('/api/v1/address', {
      params: {
        search,
      },
    });
    return {
      total: data.total,
      items: data.items,
    }
  }
}
