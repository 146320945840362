import type { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styles from './LandingLayout.module.css';
import { ReactComponent as LogoIcon } from '../assets/icons/logo.svg';
import classNames from 'classnames';

export const LandingLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={classNames('bg-brand-3',styles.layout)}>
      <header className={styles.header}>
        <a href="https://mypax.ru" target="_blank" rel="noopener noreferrer">
          <LogoIcon className={styles.logo}/>
        </a>
        
        <div className={styles.navLinks}>
          <Link to="https://mypax.ru" className={styles.link}>
            Как это работает
          </Link>
          <Link to="https://mypax.ru" className={styles.link}>
            Отзывы
          </Link>
          <Link to="https://mypax.ru" className={styles.link}>
            Помощь
          </Link>
        </div>

        <div className={styles.authButtons}>
          <Link to="/client/login" className={styles.link}>
            Войти
          </Link>
          <Link to="/client/register" className={classNames('bg-brand-4', styles.registerButton)}>
            Зарегистрироваться
          </Link>
        </div>
      </header>

      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};