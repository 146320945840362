import { FC } from "react";
import classNames from "classnames";
import styles from "./StepIndicator.module.css"
interface Step {
  label: string;
  title: string;
}

interface StepIndicatorProps {
  currentStep: number;
  steps: Step[];
}

export const StepIndicator: FC<StepIndicatorProps> = ({ currentStep, steps }) => {
  return (
    <div className={styles.indicator}>
      {steps.map((step, index) => (
        <div
          key={index}
          className={classNames(
            styles.item,
            currentStep === index ? "border-brand-2 " : "opacity-50",
             "flex-grow"
          )}
        >
          <span
            className={classNames(
              "mr-2 w-3 h-3 flex items-center justify-center rounded-full",
              currentStep >= index ? "bg-brand-2 text-blue-500" : "bg-brand-2 text-gray-500"
            )}
          >
            {index + 1}
          </span>
          <span
            className={classNames(
              styles.label,
              currentStep >= index ? "text-black" : "text-gray-500"
            )}
          >
            {step.label}
          </span>
        </div>
      ))}
    </div>
  );
};
