import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CommonApi, ReceiptApi } from '../../api';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { ConfirmOrderModal } from './ConfirmOrderModal';
import { DoctorAddMedicineToOrderForm } from './DoctorAddMedicineToOrderForm';
import { OrderMedicine } from './DoctorAddMedicineToOrderForm';
import { formatMedicinePlural, formatMoney } from '../../utils';
import { AddressAutofill } from '../molecules/AddressAutofill';
import { Select } from '../atoms';
import styles from './Step.module.css';

interface Step1Props {
    onPrev: () => void;
    formData: any;
    userId: number;
}

interface FormFields {
    address: number;
    payment_method: number;
    description: string;
}

export const Step1: FC<Step1Props> = ({ onPrev, formData, userId }) => {

    const [receiptId, setReceiptId] = useState<number | undefined>();
    const { mutate } = useMutation({
      mutationFn: ReceiptApi.clientCreate,
      onSuccess(response) {
        setReceiptId(response.receipt_id);
      },
    });
    const [medicineList, setMedicineList] = useState<OrderMedicine[]>([]);
  
    const { data: timeList = [] } = useQuery({
      queryKey: [CommonApi.KEY, 'getReference', 202],
      queryFn: () => CommonApi.getReference(202),
    });
  
    const { data: paymentList = [] } = useQuery({
      queryKey: [CommonApi.KEY, 'getReference', 206],
      queryFn: () => CommonApi.getReference(206),
    });
  
    useQuery({
      queryKey: [CommonApi.KEY, 'getRefHeaders'],
      queryFn: CommonApi.getRefHeaders,
    });
  
    const {
      control,
      getValues,
      formState: { errors },
      register,
      reset,
      trigger,
      watch, 
    } = useForm<FormFields>(); 
  
    const closeConfirmOrderModal = useCallback(() => {
      setReceiptId(undefined);
      reset();
    }, [reset]);
  
    const createOrder = useCallback(async () => {
      const isValid = await trigger();
      if (!isValid) {
        return;
      }
      const [description, address, payment_method] = getValues(['description', 'address', 'payment_method']);
      mutate({
        description,
        patient_id: userId,
        address: address,
        payment_method,
        receipt_items: medicineList.flatMap((medicine) =>
          medicine.time.map((time) => ({
            total_amount: medicine.totalCount,
            amount: Number(time.count),
            duration: medicine.duration,
            start: medicine.start,
            daytime_id: time.timeId,
            frequency_id: medicine.frequency.id,
            eating_id: time.eatingId,
            medicine_id: medicine.medicine.id,
          }))
        ),
      });
    }, [getValues, medicineList, mutate, trigger]);

    // console.log(`АЙДИ ЮЗЕРА ${userId}`);

    return (
      <div>
        <ConfirmOrderModal 
          receiptId={receiptId}
          address={getValues('address')}
          onClose={closeConfirmOrderModal}
         />
        <div className={styles.gridStep1Container}>
          <div className={classNames(styles.column, "text-brand-1  rounded-l-lg")}>
            <header className={classNames(styles.header, "bg-brand-6 rounded-tl-lg")}>
              <h2 className={classNames(styles.header,"leading-normal font-bold")}>Детали</h2>
            </header>
                <div className="flex gap-[12px] p-[24px] border-b border-dashed border-brand-1/50">
                    <Controller
                    control={control}
                    name="address"
                    rules={{
                        required: true,
                    }}
                    render={({ field, fieldState }) => (
                        <AddressAutofill
                          className="flex-1"
                          error={fieldState.error}
                          onChange={field.onChange}
                          value={field.value}
                          patientId={userId}
                        />
                    )}
                    />
                </div>
                <DoctorAddMedicineToOrderForm
                onAdd={(medicine) => setMedicineList((current) => current.concat([medicine]))}
                />
            </div>
            <div className={classNames(styles.column, "rounded-r-lg text-brand-1")}>
            <div className="grow">
              <header className={classNames(styles.header, "bg-brand-6 rounded-tl-lg")}>
                <h2 className={classNames(styles.header,"leading-normal font-bold")}>Заказ</h2>
              </header>
                <div className="pt-[12px] pb-[24px] px-[24px] border-b-[4px] border-brand-3">
                <table className="table">
                    <thead>
                    <tr>
                        <th className="w-[52px]">&nbsp;</th>
                        <th className="text-left">Лекарство</th>
                        <th className="text-left">Частота приёма</th>
                        <th className="text-left">Цена</th>
                    </tr>
                    </thead>
                    <tbody>
                    {medicineList.length === 0 && (
                        <tr>
                        <td className="text-center" colSpan={4}>
                            Пусто
                        </td>
                        </tr>
                    )}
                    {medicineList.map(({ totalCount, count, frequency, medicine, time, duration }, index) => (
                        <tr className="leading-[24px]" key={index}>
                        <td className="w-[52px]">
                            <button
                            className="inline-flex items-center justify-center w-[32px] h-[32px] bg-brand-3 rounded-md"
                            onClick={() =>
                                setMedicineList((current) =>
                                current.filter((_, medicineIndex) => medicineIndex !== index),
                                )
                            }
                            type="button"
                            >
                            <TrashIcon />
                            </button>
                        </td>
                        <td>
                            <strong className="block">{medicine.name}</strong>
                            <span className="text-brand-1/40">x{totalCount}</span>
                        </td>
                        <td>
                            <span className="block">
                            {totalCount} {formatMedicinePlural(totalCount)}&nbsp;{frequency.value}
                            </span>
                            <span className="text-brand-1/40">
                            {time.map((t) => `${t.value}`).join(', ')}
                            </span>
                        </td>
                        <td>{formatMoney(medicine.medicine_price * count * duration)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>
                {medicineList.length > 0 && (
                <label className="block p-[24px]">
                    <span className="mb-[12px] block">Комментарий к заказу</span>
                    <textarea
                    {...register('description', {
                        required: true,
                    })}
                    className={classNames('input', {
                        'input--invalid': Boolean(errors.description),
                    })}
                    placeholder="Сообщение"
                    rows={5}
                    />
                </label>
                )}
                <Controller
                control={control}
                name="payment_method"
                rules={{
                    required: true,
                }}
                render={({ field, fieldState }) => {
                    return (
                    <Select
                        className="mb-[24px]"
                        error={fieldState.error}
                        onChange={field.onChange}
                        options={paymentList.map((payment) => ({
                        label: payment.value,
                        value: payment.id,
                        }))}
                        placeholder="Способ оплаты"
                        value={field.value}
                    />
                    );
                }}
                />
            </div>
            <footer className="flex justify-end p-[24px] border-t border-brand-1/50 border-dashed">
                <button
                className="button button--success px-[32px]"
                disabled={medicineList.length === 0}
                onClick={() => createOrder()}
                type="button"
                >
                Сформировать заказ
                </button>
            </footer>
            </div>
        </div>
      </div>
    );
};
