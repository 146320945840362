import { useState, FC } from "react";
import { Step0 } from "../components/organisms/Step0";
import { Step1 } from "../components/organisms/Step1";
import { LandingLayout } from "../layouts";
import { StepIndicator } from "../components";

export const LandingFormPage: FC = () => {
  const [currentStep, setCurrentStep] = useState<0 | 1>(0);
  const [formData, setFormData] = useState({});
  const [userId, setUserId] = useState<number | null>(null);

  const nextStep = (data: any) => {
    setFormData((prev) => ({ ...prev, ...data }));
    if (data.userId) setUserId(data.userId); 
    setCurrentStep(1);
  };

  const prevStep = () => setCurrentStep(0);

  const steps = [
    { label: 'Информация для входа', title: 'Шаг 1' },
    { label: 'Детали заказа', title: 'Шаг 2' }
  ];

  return (
    <LandingLayout>
       <div className="min-h-screen bg-gray-100 flex flex-col items-center px-4 md:px-8 lg:px-16">
        <StepIndicator steps={steps} currentStep={currentStep} />
        
        {currentStep === 0 && <Step0 onNext={nextStep} />}
        {currentStep === 1 && userId !== null && <Step1 onPrev={prevStep} formData={formData} userId={userId} />}
      </div>
    </LandingLayout>
  );
};
